import { Grid } from '@mui/material';
import { Logo } from '../Logo/Logo';
import './NoContextPage.css';

type NoContextPageProps = {
	children: React.ReactNode;
};

export const NoContextPage: React.FC<NoContextPageProps> = ({ children }) => (
	<Grid container className="NoContextPage">
		<Grid item xs className="NoContextPage__logo">
			<Logo displayName={true} />
		</Grid>
		<Grid item xs>
			<Grid
				container
				className="NoContextPage__content"
				justifyContent="center"
				alignItems="center"
			>
				<Grid item>{children}</Grid>
			</Grid>
		</Grid>
	</Grid>
);
