import { styled } from '@mui/material/styles';
import { lazy, Suspense } from 'react';
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';
import { defaults as reactSweetStateDefault } from 'react-sweet-state';
import { I18nextProvider } from '../../config/I18nextProvider';
import { ReactQueryProvider } from '../../config/ReactQueryProvider';
import { ThemeRegistry } from '../../config/ThemeRegistry';
import { AppBar } from '../AppBar/AppBar';
import { AuthOverlay } from '../AuthOverlay/AuthOverlay';
import { ErrorBoundaryWithContext } from '../ErrorBoundaryWithContext/ErrorBoundaryWithContext';
import { Spinner } from '../loader/Spinner';
import { NotFound } from '../NotFound/NotFound';
import { Notification } from '../Notification/Notification';

import './App.css';

reactSweetStateDefault.devtools = process.env.NODE_ENV === 'development';
const ROOT_APP_REDIRECT_PATH = '/yield-management/flash';

type AppLayoutProps = {
	children: React.ReactNode;
};

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => (
	<AuthOverlay>
		<AppBar />
		<StyledMain>
			<ErrorBoundaryWithContext>
				<Suspense fallback={<Spinner />}>{children}</Suspense>
			</ErrorBoundaryWithContext>
		</StyledMain>
	</AuthOverlay>
);

const router = createBrowserRouter(
	[
		{
			element: (
				<AppLayout>
					<Outlet />
				</AppLayout>
			),
			children: [
				{
					path: '',
					element: <Navigate replace to={ROOT_APP_REDIRECT_PATH} />,
				},
				{
					path: '/me/*',
					Component: lazy(() => import(/*  webpackChunkName: "me" */ '../../modules/me')),
				},
				{
					path: '/admin/*',
					Component: lazy(
						() => import(/*  webpackChunkName: "admin" */ '../../modules/admin'),
					),
				},
				{
					path: '/download-center/*',
					Component: lazy(
						() =>
							import(
								/*  webpackChunkName: "downloadCenter" */ '../../modules/downloadCenter'
							),
					),
				},
				{
					path: '/authorizations/*',
					Component: lazy(
						() =>
							import(
								/*  webpackChunkName: "authorizations" */ '../../modules/authorizations'
							),
					),
				},
				{
					path: '/yield-management/*',
					Component: lazy(
						() =>
							import(
								/*  webpackChunkName: "yieldManagement" */ '../../modules/yieldManagement'
							),
					),
				},
				{
					path: '/accounting/*',
					Component: lazy(
						() =>
							import(
								/*  webpackChunkName: "accounting" */ '../../modules/accounting'
							),
					),
				},
				{
					path: '*',
					element: <NotFound />,
				},
			],
		},
	],
	{ basename: process.env.PUBLIC_URL },
);

const StyledMain = styled('main')(({ theme }) => ({
	padding: theme.spacing(2),
	paddingTop: theme.spacing(4),
}));

export const App = () => {
	return (
		<ThemeRegistry>
			<I18nextProvider>
				<ReactQueryProvider>
					<RouterProvider router={router} />
				</ReactQueryProvider>
				<Notification />
			</I18nextProvider>
		</ThemeRegistry>
	);
};
