import TranslateIcon from '@mui/icons-material/Translate';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

type LanguageItemProps = {
	lang: string;
};

const LanguageItem: React.FC<LanguageItemProps> = ({ lang }) => {
	const { t, i18n } = useTranslation();
	const menuItem = (
		<MenuItem>
			<ListItemIcon sx={{ color: 'inherit' }}>
				{i18n.language === lang && <ArrowRightIcon />}
			</ListItemIcon>
			<ListItemText primary={t(`locale-${lang}`)} />
		</MenuItem>
	);
	if (i18n.language === lang) {
		return menuItem;
	}
	return (
		<Link to={`?lng=${lang}`} reloadDocument>
			{menuItem}
		</Link>
	);
};

export const LocaleSwitcher = () => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<>
			<ListItemButton
				id="locale-switcher-button"
				aria-controls={open ? 'locale-switcher-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
			>
				<ListItemIcon sx={{ color: 'inherit' }}>
					<TranslateIcon />
				</ListItemIcon>
				<ListItemText primary={t('menu.label.locale-switcher')} disableTypography={true} />
			</ListItemButton>
			<Menu
				id="locale-switcher-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'locale-switcher-button',
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
			>
				<LanguageItem lang="fr" />
				<LanguageItem lang="en" />
			</Menu>
		</>
	);
};
