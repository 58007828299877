import { LinearProgress } from '@mui/material';
import './LinearLoaderContainer.css';

type LinearLoaderContainerProps = {
	isLoading: boolean;
	children: React.ReactNode;
};

export const LinearLoaderContainer: React.FC<LinearLoaderContainerProps> = ({
	isLoading,
	children,
}) => (
	<div className={`LinearLoader-container ${isLoading ? 'LinearLoader-container__loading' : ''}`}>
		{children}
		{isLoading && (
			<div className="LinearLoader-overlay">
				<div>
					<LinearProgress />
				</div>
			</div>
		)}
	</div>
);
