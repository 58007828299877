import { Component, ErrorInfo } from 'react';
import { trackError } from '../../services/errorTracking';
import { EnhancedHotel } from '../../types/hotel';
import { useAccountForSure, useCurrentWorkHotelForSure } from '../../utils/user';

type ErrorBoundaryProps = {
	account: { email: string };
	currentWorkHotel: EnhancedHotel;
	children: React.ReactNode;
};

type ErrorBoundaryState = {
	hasError: boolean;
};

// inpired by https://fr.reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: Error) {
		// Update state in order to display UI fallback on next render
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		const { account, currentWorkHotel } = this.props;
		const extraData = {};
		trackError(
			error,
			{ componentStack: errorInfo.componentStack },
			account,
			currentWorkHotel,
			extraData,
		);
	}

	render() {
		if (this.state.hasError) {
			return (
				<div>
					<h1>Erreur ... désolé =(</h1>
					Les administrateurs ont été notifiés.
				</div>
			);
		}

		return this.props.children;
	}
}

type ErrorBoundaryWithContextProps = {
	children: React.ReactNode;
};

export const ErrorBoundaryWithContext: React.FC<ErrorBoundaryWithContextProps> = ({ children }) => {
	const account = useAccountForSure();
	const currentWorkHotel = useCurrentWorkHotelForSure();
	return (
		<ErrorBoundary account={account} currentWorkHotel={currentWorkHotel}>
			{children}
		</ErrorBoundary>
	);
};
