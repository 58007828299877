// FIXME [typescript - form] better typing for forms or move away from Formik
import { TextField as MuiTextField } from '@mui/material';
import {
	FieldInputProps,
	FieldMetaProps,
	FormikContextType,
	useField,
	useFormikContext,
} from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;

type FieldToTextField = {
	field: FieldInputProps<unknown>;
	meta: FieldMetaProps<unknown>;
	form: FormikContextType<unknown>;
	disabled?: boolean;
};
const fieldToTextField = ({
	field,
	meta: { error, touched },
	form: { isSubmitting },
	disabled,
}: FieldToTextField) => {
	const showError = touched && !!error;
	return {
		...field,
		error: showError,
		helperText: showError ? error : '',
		disabled: disabled !== undefined ? disabled : isSubmitting,
	};
};

type TextFieldProps = {
	labelKey: string;
	fullWidth?: boolean;
	name: string;
	type: 'text' | 'email' | 'password';
	required?: boolean;
	disabled?: boolean;
	minlength?: number;
	maxlength?: number;
	additionalValidation?: [(value: any) => boolean, string];
};

export const TextField: React.FC<TextFieldProps> = ({
	labelKey,
	fullWidth = true,
	type,
	name,
	required = false,
	disabled = false,
	minlength,
	maxlength,
	additionalValidation,
}) => {
	const { t } = useTranslation();
	const validate = useCallback(
		(value: any) => {
			let error;
			if (required && !value) {
				error = t('error.field.required');
			} else if (!!minlength && value.length < minlength) {
				error = t('error.field.min-length');
			} else if (!!maxlength && value.length > maxlength) {
				error = t('error.field.max-length');
			} else if (type === 'email' && !EMAIL_REGEX.test(value)) {
				error = t('error.field.email');
			} else if (!!additionalValidation && !additionalValidation[0](value)) {
				error = t(additionalValidation[1]);
			}
			return error;
		},
		[type, t, required, minlength, maxlength, additionalValidation],
	);
	const [field, meta] = useField({ type, name, validate });
	const form = useFormikContext();
	return (
		<MuiTextField
			{...fieldToTextField({ field, meta, form, disabled })}
			label={t(labelKey) + (required ? ' *' : '')}
			fullWidth={fullWidth}
			margin="normal"
			type={type}
			id={name}
			variant="standard"
		/>
	);
};
