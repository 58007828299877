import MoreIcon from '@mui/icons-material/MoreHoriz';
import { Card, CardContent, CardHeader, Fab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type RegularCardProps = {
	title?: string;
	titleKey?: string;
	headerActionLink?: string;
	content: React.ReactNode;
};

export const RegularCard: React.FC<RegularCardProps> = ({
	title,
	titleKey,
	headerActionLink,
	content,
}) => {
	const { t } = useTranslation();
	const theTitle = title || (titleKey && t(titleKey));
	return (
		<Card
			elevation={0}
			sx={{
				display: 'inline-block',
				width: '100%',
				margin: '25px 0 0 0',
				overflow: 'visible',
			}}
		>
			{theTitle && (
				<CardHeader
					title={theTitle}
					sx={{
						margin: '-20px 15px 0',
						borderRadius: '3px',
						padding: '15px',
						backgroundColor: 'primary.main',
						color: 'primary.contrastText',
						'& .MuiCardHeader-title': {
							fontSize: '1.125em',
						},
					}}
					action={
						headerActionLink && (
							<Link to={headerActionLink}>
								<Fab
									color="primary"
									size="small"
									sx={{
										margin: '-10px -10px -10px 0',
										transform: 'scale(2)',
										alignSelf: 'auto',
									}}
								>
									<MoreIcon />
								</Fab>
							</Link>
						)
					}
				/>
			)}
			<CardContent>{content}</CardContent>
		</Card>
	);
};
