import { Alert, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNotification, useNotificationActions } from '../../utils/notifications';

const ANCHOR_ORIGIN = { vertical: 'bottom', horizontal: 'center' } as const;

export const Notification = () => {
	const notification = useNotification();
	const { cleanNotification } = useNotificationActions();
	const { t } = useTranslation();
	return (
		<Snackbar
			open={!!notification}
			autoHideDuration={4000}
			onClose={cleanNotification}
			anchorOrigin={ANCHOR_ORIGIN}
		>
			<div>
				{notification && (
					<Alert severity={notification.isSuccess ? 'success' : 'error'}>
						{t(notification.message)}
					</Alert>
				)}
			</div>
		</Snackbar>
	);
};
