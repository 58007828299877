import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

type SubmitButtonProps = {
	disabled: boolean;
	labelKey?: string;
};

export const SubmitButton: React.FC<SubmitButtonProps> = ({ disabled, labelKey = 'form.save' }) => {
	const { t } = useTranslation();
	return (
		<Button variant="contained" color="primary" type="submit" disabled={disabled}>
			{t(labelKey)}
		</Button>
	);
};
