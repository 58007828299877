import { Action, createActionsHook, createStateHook, createStore } from 'react-sweet-state';

// ****************************************************************************
// Store
// ****************************************************************************
type State = {
	notification: {
		message: string;
		isSuccess: boolean;
		isError: boolean;
	} | null;
};

const initialState: State = {
	notification: null,
};

const cleanNotification =
	(): Action<State> =>
	({ setState }) => {
		setState({ notification: null });
	};

const notifySuccess =
	(message: string): Action<State> =>
	({ setState }) => {
		setState({ notification: { message, isSuccess: true, isError: false } });
	};

const notifyError =
	(message: string): Action<State> =>
	({ setState }) => {
		setState({ notification: { message, isSuccess: false, isError: true } });
	};

const Store = createStore({
	initialState,
	actions: {
		cleanNotification,
		notifySuccess,
		notifyError,
	},
	name: 'NotificationStore',
});

// ****************************************************************************
// State hooks
// ****************************************************************************
export const useNotification = createStateHook(Store, {
	selector: (state) => state.notification,
});

// ****************************************************************************
// Action hooks
// ****************************************************************************
export const useNotificationActions = createActionsHook(Store);
