import { FormikHelpers, FormikValues } from 'formik';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BadRequestException } from '../../exceptions';
import { useNotificationActions } from '../../utils/notifications';

export const useFormManagement = <T extends FormikValues>(
	onSubmit: (values: T) => Promise<void>,
) => {
	const isMounted = useRef(false);
	const { notifyError } = useNotificationActions();
	const { t } = useTranslation();
	useEffect(() => {
		isMounted.current = true;
		return () => {
			isMounted.current = false;
		};
	}, []);
	const managedOnSubmit = useCallback(
		(values: T, { setSubmitting, setFieldError }: FormikHelpers<T>) => {
			let promise = Promise.resolve();
			if (onSubmit) {
				promise = onSubmit(values);
			}
			promise.then(
				() => {
					if (isMounted.current) {
						setSubmitting(false);
					}
				},
				(rejection) => {
					if (rejection instanceof BadRequestException) {
						Object.entries(rejection.errors).forEach(([fieldName, errorKey]) => {
							setFieldError(fieldName, t(errorKey));
						});
					} else {
						notifyError('error.technical');
					}
					setSubmitting(false);
				},
			);
		},
		[onSubmit, notifyError, t],
	);
	return { managedOnSubmit };
};
