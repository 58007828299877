import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Action, createActionsHook, createStateHook, createStore } from 'react-sweet-state';

type PageTitleAddOn = () => JSX.Element;
// ****************************************************************************
// Store
// ****************************************************************************
type State = {
	pageTitle?: string;
	PageTitleAddOnComponent?: PageTitleAddOn;
};

const initialState: State = {
	pageTitle: undefined,
	PageTitleAddOnComponent: undefined,
};

const setPageTitle =
	({
		pageTitle,
		PageTitleAddOnComponent,
	}: {
		pageTitle?: string;
		PageTitleAddOnComponent?: PageTitleAddOn;
	}): Action<State> =>
	({ setState }) => {
		setState({ pageTitle, PageTitleAddOnComponent });
	};

const Store = createStore({
	initialState,
	actions: {
		setPageTitle,
	},
	name: 'PageTitleStore',
});

// ****************************************************************************
// State hooks
// ****************************************************************************
export const usePageTitle = createStateHook(Store, {
	selector: (state) => state,
});

// ****************************************************************************
// Action hooks
// ****************************************************************************
const usePageTitleInternalActions = createActionsHook(Store);

export function useSetPageTitle(
	pageTitleKey: undefined,
	pageTitle: string,
	PageTitleAddOnComponent?: PageTitleAddOn,
): void;

export function useSetPageTitle(
	pageTitleKey: string,
	pageTitle?: undefined,
	PageTitleAddOnComponent?: PageTitleAddOn,
): void;

export function useSetPageTitle(
	pageTitleKey?: string,
	pageTitle?: string,
	PageTitleAddOnComponent?: PageTitleAddOn,
) {
	const { t } = useTranslation();
	const { setPageTitle } = usePageTitleInternalActions();
	useEffect(() => {
		const computedPageTitle = pageTitle || (pageTitleKey && t(pageTitleKey)) || pageTitleKey;
		setPageTitle({ pageTitle: computedPageTitle, PageTitleAddOnComponent });
		document.title = computedPageTitle || 'Happilit';
		return () => {
			setPageTitle({ pageTitle: undefined, PageTitleAddOnComponent: undefined });
			document.title = 'Happilit';
		};
	}, [t, pageTitle, pageTitleKey, PageTitleAddOnComponent, setPageTitle]);
}
