// FIXME [typescript - form] better typing for forms or move away from Formik
import { Card, CardActions, CardContent } from '@mui/material';
import { Form as FormikForm, Formik, FormikValues } from 'formik';
import { LinearLoaderContainer } from '../loader/LinearLoaderContainer';
import Debug from './Debug';
import { useFormManagement } from './useFormManagement';
import { ComponentProps } from 'react';

type FormCardProps = {
	onSubmit: (values: any) => Promise<void>;
	content: ({ values }: { values: FormikValues }) => React.ReactNode;
	actions: ({ canSubmit }: { canSubmit: boolean }) => React.ReactNode;
} & Omit<ComponentProps<typeof Formik>, 'onSubmit' | 'children'>;

export const FormCard: React.FC<FormCardProps> = ({ onSubmit, content, actions, ...props }) => {
	const { managedOnSubmit } = useFormManagement(onSubmit);
	return (
		<Formik {...props} onSubmit={managedOnSubmit}>
			{({ values, isSubmitting, errors }) => {
				return (
					<FormikForm>
						<LinearLoaderContainer isLoading={isSubmitting}>
							<Card elevation={0}>
								<CardContent>{content({ values })}</CardContent>
								<CardActions>
									{actions({
										canSubmit: !isSubmitting && !Object.keys(errors).length,
									})}
								</CardActions>
							</Card>
						</LinearLoaderContainer>
						<Debug />
					</FormikForm>
				);
			}}
		</Formik>
	);
};
