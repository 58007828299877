export class ForbiddenException extends Error {
	status: number;

	constructor() {
		super();
		this.name = 'ForbiddenException';
		this.message = 'error.forbidden';
		this.status = 403;
		this.stack = new Error().stack;
	}
}
