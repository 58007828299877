// Adapted from https://css-tricks.com/importance-javascript-abstractions-working-remote-data/
import {
	BadRequestException,
	FormatException,
	NotFoundException,
	TechnicalException,
	UnauthorizedException,
} from '../exceptions';
import { ErrorObject } from 'serialize-error';

/**
 * Simple service for generating different HTTP codes. Useful for
 * testing how your own scripts deal with varying responses.
 */
const baseUrl = '';

const handleTechnicalError = (rejection: Error) => {
	console.info('handleTechnicalError', rejection);
	throw new TechnicalException(rejection);
};

/**
 * fetch() will only reject a promise if the user is offline,
 * or some unlikely networking error occurs, such a DNS lookup failure.
 * However, there is a simple `ok` flag that indicates
 * whether an HTTP response's status code is in the successful range.
 */
const handleBusinessError = (url: string) => (res: Response) => {
	switch (res.status) {
		case 400:
			return res.json().then(({ errors }) => {
				throw new BadRequestException(errors);
			});
		case 401:
			throw new UnauthorizedException();
		case 404:
			throw new NotFoundException(url);
		default:
			return res.ok ? res : Promise.reject(res);
	}
};

/**
 * Check whether the content type is correct before you process it further.
 */
const handleContentType = (response: Response) => {
	const contentType = response.headers.get('content-type');
	if (contentType && contentType.includes('application/json')) {
		return response.json();
	}
	throw new FormatException();
};

const handleRefresh =
	<T>(retryAction: () => Promise<T>) =>
	(rejection: Error) => {
		if (rejection instanceof UnauthorizedException) {
			return basicPost('/api/auth/refresh', {}).then(retryAction);
		}
		return Promise.reject(rejection);
	};

const basicGet = <T>(endpoint: string, queryParams?: URLSearchParams) => {
	const url = `${baseUrl}${endpoint}?${queryParams ? queryParams.toString() : ''}`;
	return fetch(url, {
		method: 'GET',
		headers: new Headers({
			Accept: 'application/json',
		}),
		credentials: 'same-origin',
	})
		.then(handleBusinessError(url), handleTechnicalError)
		.then(handleContentType) as Promise<T>;
};

export const get = <T>(endpoint: string, queryParams?: URLSearchParams) => {
	return basicGet<T>(endpoint, queryParams).catch(
		handleRefresh<T>(() => basicGet(endpoint, queryParams)),
	);
};

type PostOrPutBody = Record<string, unknown> | ErrorObject;

const basicPostOrPut =
	(method: 'POST' | 'PUT') =>
	<T>(endpoint: string, body: PostOrPutBody) => {
		const url = `${baseUrl}${endpoint}`;
		return fetch(url, {
			method: method,
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(body),
			credentials: 'same-origin',
		})
			.then(handleBusinessError(url), handleTechnicalError)
			.then(handleContentType) as Promise<T>;
	};

const basicPost = basicPostOrPut('POST');

export const post = <T>(endpoint: string, body: PostOrPutBody) => {
	return basicPost<T>(endpoint, body).catch(handleRefresh<T>(() => basicPost(endpoint, body)));
};

const basicPut = basicPostOrPut('PUT');

export const put = <T>(endpoint: string, body: PostOrPutBody) => {
	return basicPut<T>(endpoint, body).catch(handleRefresh<T>(() => basicPut(endpoint, body)));
};

const basicRemove = (endpoint: string) => {
	const url = `${baseUrl}${endpoint}`;
	return fetch(url, {
		method: 'DELETE',
		credentials: 'same-origin',
	})
		.then(handleBusinessError(url), handleTechnicalError)
		.then(handleContentType);
};

export const remove = (endpoint: string) => {
	return basicRemove(endpoint).catch(handleRefresh(() => basicRemove(endpoint)));
};
