import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { BusinessRoute } from './businessRoutes';
import { LocaleSwitcher } from './LocaleSwitcher';

const Divider = styled('li')({
	margin: '5px 0',
	borderBottom: '1px solid rgba(180, 180, 180, 0.3)',
});

const StyledListItem = styled('li')(({ theme }) => ({
	'& .MuiListItemButton-root': {
		padding: '5px 10px',
		borderRadius: '3px',
		color: theme.palette.primary.contrastText,
		lineHeight: '1.2em',
	},
	'& a': {
		textDecoration: 'none',
	},
	'& a.active .MuiListItemButton-root': {
		backgroundColor: theme.palette.primary.main,
	},
	'&:hover > a .MuiListItemButton-root, &:hover > .MuiListItemButton-root': {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.dark,
	},
}));

type LinkListProps = {
	routes: BusinessRoute[];
	toggleSidebar: () => void;
};

export const LinkList: React.FC<LinkListProps> = ({ routes, toggleSidebar }) => {
	const { t } = useTranslation();
	const hasAtLeastOneRoute = routes.filter((route) => route.type !== 'divider').length > 0;
	if (!hasAtLeastOneRoute) {
		return null;
	}
	return (
		<List>
			{routes.map((route, index) =>
				route.type === 'divider' ? (
					<Divider key={index} />
				) : route.type === 'link' ? (
					<StyledListItem key={route.to}>
						<NavLink to={route.to} onClick={toggleSidebar}>
							<ListItemButton>
								<ListItemIcon sx={{ color: 'inherit' }}>
									<route.icon />
								</ListItemIcon>
								<ListItemText
									primary={t(route.labelKey)}
									disableTypography={true}
								/>
							</ListItemButton>
						</NavLink>
					</StyledListItem>
				) : route.type === 'action' ? (
					<StyledListItem key={index}>
						<ListItemButton onClick={route.action}>
							<ListItemIcon sx={{ color: 'inherit' }}>
								<route.icon />
							</ListItemIcon>
							<ListItemText primary={t(route.labelKey)} disableTypography={true} />
						</ListItemButton>
					</StyledListItem>
				) : route.type === 'localeSwitcher' ? (
					<StyledListItem key={index}>
						<LocaleSwitcher />
					</StyledListItem>
				) : null,
			)}
		</List>
	);
};
