import { RegularCard } from '../RegularCard/RegularCard';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const NotFound = () => {
	const { t } = useTranslation();
	return (
		<RegularCard
			titleKey="menu.label.notfound"
			content={
				<div>
					{t('error.page.not-found')}
					<br />
					<Link to="/">{t('error.page.not-found.return-to-home')}</Link>
				</div>
			}
		/>
	);
};
