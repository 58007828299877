import '@fontsource/roboto/500.css';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './components/App/App';

// unregister all potential service workers
navigator.serviceWorker?.getRegistrations().then((registrations) => {
	for (const registration of registrations) {
		registration.unregister();
	}
});

const rootElement = document.getElementById('root');
if (rootElement) {
	const root = createRoot(rootElement);
	root.render(
		<StrictMode>
			<App />
		</StrictMode>,
	);
}
