/**
 * Following documentation at https://mui.com/material-ui/guides/next-js-app-router/
 */
import { createTheme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React from 'react';

declare module '@mui/material' {
	type StatusColors = {
		contrastText: string;
		dark: string;
		light: string;
		main: string;
	};
	type EvolutionColors = {
		contrastText: string;
		main: string;
	};
	type TemperatureColors = {
		contrastText: string;
		main: string;
	};

	interface PaletteOptions {
		status: {
			info: StatusColors;
			success: StatusColors;
			danger: StatusColors;
		};
		evolution: {
			positive: EvolutionColors;
			negative: EvolutionColors;
		};
		temperature: {
			lowest: TemperatureColors;
			low: TemperatureColors;
			average: TemperatureColors;
			high: TemperatureColors;
			highest: TemperatureColors;
			outOfRange: TemperatureColors;
		};
		specialDays: {
			weekend: string;
			today: string;
		};
	}

	interface Theme {
		palette: {
			primary: StatusColors;
		} & PaletteOptions;
	}
}

const theme = createTheme({
	palette: {
		primary: {
			contrastText: '#ffffff',
			dark: '#0080b7',
			light: '#82e1ff',
			main: '#47afea',
		},
		status: {
			info: {
				contrastText: '#000000',
				dark: '#82b3c9',
				light: '#e6ffff',
				main: '#b3e5fc',
			},
			success: {
				contrastText: '#000000',
				dark: '#268734',
				light: '#8eeb8f',
				main: '#5cb860',
			},
			danger: {
				contrastText: '#000000',
				dark: '#ff8d7a',
				light: '#ff8d7a',
				main: '#f55a4e',
			},
		},
		evolution: {
			negative: {
				contrastText: '#000000',
				main: '#f55a4e',
			},
			positive: {
				contrastText: '#000000',
				main: '#5cb860',
			},
		},
		temperature: {
			lowest: {
				contrastText: '#000000',
				main: '#a5d6a7',
			},
			low: {
				contrastText: '#000000',
				main: '#5cb860',
			},
			average: {
				contrastText: '#000000',
				main: '#2196f3',
			},
			high: {
				contrastText: '#000000',
				main: '#ffeb3b',
			},
			highest: {
				contrastText: '#000000',
				main: '#f55a4e',
			},
			outOfRange: {
				contrastText: '#ffffff',
				main: '#000000',
			},
		},
		specialDays: {
			weekend: 'rgb(201, 229, 252, 0.4)',
			today: 'beige',
		},
	},
	components: {
		MuiTable: {
			variants: [
				{
					props: { size: 'small' },
					style: {
						'& th, & td': {
							padding: '0 8px',
						},
					},
				},
			],
		},
	},
});

type ThemeRegistryProps = {
	children: React.ReactNode;
};

export const ThemeRegistry: React.FC<ThemeRegistryProps> = ({ children }) => {
	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				{children}
			</ThemeProvider>
		</StyledEngineProvider>
	);
};
