import { Suspense } from 'react';
import { usePageTitle } from '../../utils/pageTitle';
import { Spinner } from '../loader/Spinner';

export const PageTitle = () => {
	const { pageTitle, PageTitleAddOnComponent } = usePageTitle();
	return (
		<>
			{pageTitle}{' '}
			{PageTitleAddOnComponent && (
				<Suspense fallback={<Spinner />}>
					<PageTitleAddOnComponent />
				</Suspense>
			)}
		</>
	);
};
