import { FormControl, FormControlLabel, FormHelperText, Switch as MuiSwitch } from '@mui/material';
import { FieldInputProps, useField, useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type FieldToSwitch = {
	field: FieldInputProps<any>; // FIXME [typescript] better types
	form: {
		isSubmitting: boolean;
	};
	disabled: boolean;
};

const fieldToSwitch = ({ field, form: { isSubmitting }, disabled }: FieldToSwitch) => {
	return {
		disabled: disabled !== undefined ? disabled : isSubmitting,
		...field,
		value: true,
		checked: field.value,
	};
};

type SwitchProps = {
	labelKey: string;
	name: string;
	required?: boolean;
	disabled?: boolean;
	additionalValidation?: [(value: any) => boolean, string];
};

export const Switch: React.FC<SwitchProps> = ({
	labelKey,
	name,
	required = false,
	disabled = false,
	additionalValidation,
}) => {
	const { t } = useTranslation();
	const validate = useCallback(
		(value: boolean) => {
			let error;
			if (required && !value) {
				error = t('error.field.required');
			} else if (!!additionalValidation && !additionalValidation[0](value)) {
				error = t(additionalValidation[1]);
			}
			return error;
		},
		[t, required, additionalValidation],
	);
	const [field, meta] = useField({ name, validate });
	const form = useFormikContext();
	const translatedErrorMsg = !!meta.error && t(meta.error);
	return (
		<FormControl error={!!translatedErrorMsg} fullWidth>
			<FormControlLabel
				control={<MuiSwitch {...fieldToSwitch({ field, form, disabled })} />}
				label={t(labelKey)}
			/>
			{!!translatedErrorMsg && meta.touched && (
				<FormHelperText>{translatedErrorMsg}</FormHelperText>
			)}
		</FormControl>
	);
};
