import { Spinner } from '../loader/Spinner';
import { NoContextPage } from '../NoContextPage/NoContextPage';
import { Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';

type SpinnerFullScreenProps = {
	loadingMessageKey?: string;
};

export const SpinnerFullScreen: React.FC<SpinnerFullScreenProps> = ({
	loadingMessageKey = 'app.loading',
}) => {
	const { t } = useTranslation();
	return (
		<NoContextPage>
			<Card>
				<CardContent>
					<Spinner />
					<div>{t(loadingMessageKey)}</div>
				</CardContent>
			</Card>
		</NoContextPage>
	);
};
