import queryString from 'query-string';
import { useEffect } from 'react';
import {
	useCurrentWorkHotel,
	useIsAuthenticated,
	useIsAuthInitializing,
	useIsChangingHotel,
	useUserActions,
} from '../../utils/user';
import { SpinnerFullScreen } from '../SpinnerFullScreen/SpinnerFullScreen';
import { ResetPassword } from './auth/ResetPassword';
import { Signin } from './auth/Signin';
import { HotelChooser } from './HotelChooser/HotelChooser';

type AuthOverlayProps = {
	children: React.ReactNode;
};

export const AuthOverlay: React.FC<AuthOverlayProps> = ({ children }) => {
	const isAuthenticated = useIsAuthenticated();
	const { initAuth } = useUserActions();
	const isAuthInitializing = useIsAuthInitializing();
	const currentWorkHotel = useCurrentWorkHotel();
	const isChangingHotel = useIsChangingHotel();

	useEffect(() => {
		initAuth();
	}, [initAuth]);
	if (isAuthInitializing) {
		return <SpinnerFullScreen />;
	}
	const resetPasswordKey = queryString.parse(window.location.search).resetPasswordKey;
	if (resetPasswordKey) {
		return <ResetPassword resetPasswordKey={`${resetPasswordKey}`} />;
	}
	if (!isAuthenticated) {
		return <Signin />;
	}
	if (!currentWorkHotel || isChangingHotel) {
		return <HotelChooser />;
	}
	return <>{children}</>;
};
