export class BadRequestException extends Error {
	errors: Record<string, string>;

	constructor(errors: Record<string, { message: string }>) {
		super();
		this.name = 'BadRequestException';
		this.stack = new Error().stack;
		this.errors = Object.entries(errors).reduce<Record<string, string>>(function (
			accu,
			[fieldKey, error],
		) {
			accu[fieldKey] = error.message;
			return accu;
		}, {});
	}
}
