import { trackError } from '../../services/errorTracking';
import settingsYield from './settings-yield.json';
import settingsAccountingSalesCategoryFamilyMapping from './settings-accounting-salesCategoryFamilyMapping.json';
import { EnhancedHotel, EnhancedSettings, HotelFeature, RawHotel } from '../../types/hotel';

const brands: readonly (keyof typeof settingsYield)[] = [
	'accor',
	'adapeiam',
	'marriott',
	'rossignol',
	'hotelCenter',
	'louvreHotels',
] as const;

const enhancedSettings = brands.reduce<Record<string, EnhancedSettings>>((accu, brandGroup) => {
	const brandGroupSettings = settingsYield[brandGroup];
	accu[brandGroup] = {
		yieldSettings: {
			segmentFamilies: brandGroupSettings.segmentFamilies,
			segments: Array.prototype.concat
				.apply(
					[],
					brandGroupSettings.segmentFamilies.map((v) => v.segments),
				)
				.sort(),
			segmentFamiliesIndiv: brandGroupSettings.segmentFamilies
				.filter((v) => v.indiv === 1)
				.map((v) => v.name),
			segmentFamiliesGroup: brandGroupSettings.segmentFamilies
				.filter((v) => v.indiv === 2 || v.indiv === 3)
				.map((v) => v.name),
			segmentFamiliesGroupLeasure: brandGroupSettings.segmentFamilies
				.filter((v) => v.indiv === 2)
				.map((v) => v.name),
			segmentFamiliesGroupBusiness: brandGroupSettings.segmentFamilies
				.filter((v) => v.indiv === 3)
				.map((v) => v.name),
			segmentFamiliesDirect: brandGroupSettings.segmentFamilies
				.filter((v) => !v.indirect)
				.map((v) => v.name),
			segmentFamiliesIndirect: brandGroupSettings.segmentFamilies
				.filter((v) => v.indirect)
				.map((v) => v.name),
			channelsMeta: brandGroupSettings.channelsMeta,
		},
		salesSettings: {
			categoryFamilyMapping: settingsAccountingSalesCategoryFamilyMapping,
		},
	};
	return accu;
}, {});

export function enhanceHotelData(hotel: undefined): null;
export function enhanceHotelData(hotel: RawHotel): EnhancedHotel;
export function enhanceHotelData(hotel: undefined | RawHotel) {
	if (!hotel) {
		return null;
	}
	let brandGroup = hotel.brandGroup;
	if (!brandGroup) {
		trackError(null, { hotel, reason: 'missing brandGroup' }, null, hotel);
	}
	return {
		...hotel,
		...enhancedSettings[brandGroup],
		isEnabled: function (feature?: HotelFeature, date = new Date()) {
			if (!feature) {
				return true;
			}
			// feature is enabled if date argument has a year greater or equal than hotel settings for this particular feature
			const featureFlag = this.features[feature];
			return (
				(typeof featureFlag === 'boolean' && featureFlag === true) ||
				(typeof featureFlag === 'number' && featureFlag <= date.getFullYear())
			);
		},
	};
}
