import { FormControl, FormHelperText, Input, InputLabel, Select as MuiSelect } from '@mui/material';
import { useField, useFormikContext } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type SelectOption = {
	label: string;
	value: string | number;
};

type SelectProps = {
	options: SelectOption[];
	labelKey: string;
	fullWidth?: boolean;
	name: string;
	required?: boolean;
	disabled?: boolean;
	additionalValidation?: [(value: any) => boolean, string];
};

const renderOption = (option: SelectOption) => (
	<option value={option.value} key={option.value}>
		{option.label}
	</option>
);
export const Select: React.FC<SelectProps> = ({
	options,
	labelKey,
	fullWidth = true,
	name,
	required = false,
	disabled = false,
	additionalValidation,
}) => {
	const { t } = useTranslation();
	const validate = useCallback(
		(value: SelectOption['value']) => {
			let error;
			if (required && !value) {
				error = t('error.field.required');
			} else if (!!additionalValidation && !additionalValidation[0](value)) {
				error = t(additionalValidation[1]);
			}
			return error;
		},
		[t, required, additionalValidation],
	);
	const [field, meta] = useField({ name, validate });
	const form = useFormikContext();
	const translatedErrorMsg = !!meta.error && t(meta.error);
	return (
		<FormControl error={!!translatedErrorMsg} fullWidth={fullWidth} margin="normal">
			<InputLabel htmlFor={name}>{t(labelKey)}</InputLabel>
			<MuiSelect
				native
				{...field}
				input={<Input id={name} />}
				disabled={disabled || form.isSubmitting}
			>
				{options.map(renderOption)}
			</MuiSelect>
			{!!translatedErrorMsg && meta.touched && (
				<FormHelperText>{translatedErrorMsg}</FormHelperText>
			)}
		</FormControl>
	);
};
