export class NotFoundException extends Error {
	status: number;
	url: string;

	constructor(url: string) {
		super();
		this.name = 'NotFoundException';
		this.message = 'error.notfound';
		this.status = 404;
		this.stack = new Error().stack;
		this.url = url;
	}
}
