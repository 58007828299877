import { Logo } from '../Logo/Logo';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const StyledLink = styled(Link)(({ theme }) => ({
	padding: '10px 0',
	display: 'flex',
	alignItems: 'center',
	textAlign: 'left',
	textDecoration: 'none',
	borderBottom: '1px solid rgba(180, 180, 180, 0.3)',
	color: theme.palette.primary.main,
	span: {
		...theme.typography.body1,
	},
	svg: {
		width: '18%',
		verticalAlign: 'middle',
		border: '0',
		margin: '0 8px',
		color: 'red',
	},
}));

type BrandProps = {
	toggleSidebar: () => void;
};

export const Brand: React.FC<BrandProps> = ({ toggleSidebar }) => (
	<StyledLink to="/" onClick={toggleSidebar}>
		<Logo />
		<span>Happilit</span>
	</StyledLink>
);
