import './Spinner.css';

export const Spinner = () => (
	<div id="loader">
		<div className="dot" />
		<div className="dot" />
		<div className="dot" />
		<div className="dot" />
		<div className="dot" />
		<div className="dot" />
		<div className="dot" />
		<div className="dot" />
		<div className="loading" />
	</div>
);
