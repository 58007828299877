import { Account } from '../types/account';
import { get, post } from './api';

// GET /account
export const fetchAccount = () => {
	return get<Account>('/api/account');
};

// POST /account
export const setAccount = (account: Account) => {
	return post<Account>('/api/account', account);
};

// POST /account/password
export const setPassword = (password: string) => {
	return post<Account>('/api/account/password', { password });
};
