import MenuIcon from '@mui/icons-material/Menu';
import { AppBar as MuiAppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCurrentWorkHotelForSure } from '../../utils/user';
import { PageTitle } from './PageTitle';
import { Sidebar } from './Sidebar';

const StyledPageTitle = styled(Typography)({
	flex: 1,
	marginLeft: 2,
	marginRight: 2,
	textAlign: 'center',
	'&::first-letter': {
		textTransform: 'uppercase',
	},
});

export const AppBar = () => {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const toggleSidebar = useCallback(() => setSidebarOpen((isOpen) => !isOpen), []);
	const currentWorkHotel = useCurrentWorkHotelForSure();
	const { t } = useTranslation();
	return (
		<>
			<Sidebar toggleSidebar={toggleSidebar} sidebarOpen={sidebarOpen} />
			<MuiAppBar position="sticky" color="inherit">
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="Menu"
						onClick={toggleSidebar}
						size="large"
					>
						<MenuIcon />
					</IconButton>
					<Typography
						variant="h6"
						color="inherit"
						sx={{
							'@media (max-width: 800px)': {
								display: 'none',
							},
						}}
					>
						{t('app.title')}
					</Typography>
					<StyledPageTitle variant="h6" color="inherit">
						<PageTitle />
					</StyledPageTitle>
					<Typography
						variant="h6"
						color="inherit"
						sx={{
							'@media (max-width: 800px)': {
								display: 'none',
							},
						}}
					>
						{currentWorkHotel.name}
					</Typography>
				</Toolbar>
			</MuiAppBar>
		</>
	);
};
