import { Account } from '../types/account';
import { post } from './api';

// POST /auth/login
export const login = (username: string, password: string) => {
	return post<Account>('/api/auth/login', { username, password });
};

// POST /auth/logout
export const logout = () => {
	return post('/api/auth/logout', {});
};

// POST /auth/passwordReset/init
export const passwordResetInit = (userId: string) => {
	return post('/api/auth/passwordReset/init', {
		userId,
	});
};

// POST /auth/passwordReset/finish
export const passwordResetFinish = (resetPasswordKey: string, userId: string, password: string) => {
	return post(`/api/auth/passwordReset/finish`, {
		userId,
		resetPasswordKey,
		password,
	});
};
