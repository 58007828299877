import { Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormCard, TextField } from '../../../components/form';
import { NoContextPage } from '../../../components/NoContextPage/NoContextPage';
import { passwordResetFinish } from '../../../services/auth';
import { useNotificationActions } from '../../../utils/notifications';

type ResetPasswordProps = {
	resetPasswordKey: string;
};

export const ResetPassword: React.FC<ResetPasswordProps> = ({ resetPasswordKey }) => {
	const { notifySuccess, notifyError } = useNotificationActions();
	const location = useLocation();
	const navigate = useNavigate();
	const handleResetPassword = useCallback(
		({ username, password }: { username: string; password: string }) => {
			return passwordResetFinish(resetPasswordKey, username, password).then(
				() => {
					notifySuccess('reset-password.success.reset-password');
					navigate(location.pathname);
				},
				() => {
					notifyError('reset-password.error.generic');
				},
			);
		},
		[notifySuccess, notifyError, location, navigate, resetPasswordKey],
	);
	const { t } = useTranslation();
	return (
		<NoContextPage>
			<FormCard
				initialValues={{ username: '', password: '', passwordConfirm: '' }}
				onSubmit={handleResetPassword}
				content={({ values }) => (
					<>
						<div className="MuiFormControl-marginNormal-40">
							{t('reset-password.form.intro')}
						</div>
						<TextField
							name="username"
							required
							labelKey="reset-password.form.username"
							type="email"
						/>
						<TextField
							name="password"
							type="password"
							required
							labelKey="reset-password.form.password"
							minlength={6}
						/>
						<TextField
							name="passwordConfirm"
							type="password"
							required
							labelKey="reset-password.form.password-confirm"
							minlength={6}
							additionalValidation={[
								(value) => value === values.password,
								'reset-password.error.password-no-match',
							]}
						/>
					</>
				)}
				actions={({ canSubmit }) => (
					<Button variant="contained" color="primary" type="submit" disabled={!canSubmit}>
						{t('reset-password.form.submit')}
					</Button>
				)}
			/>
		</NoContextPage>
	);
};
