import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import { RawHotel } from '../../../types/hotel';
import { useCurrentWorkHotel, useMyHotels, useUserActions } from '../../../utils/user';
import { NoContextPage } from '../../NoContextPage/NoContextPage';
import { HotelChooserHandle } from './HotelChooserHandle';

export const HotelChooser = () => {
	const currentWorkHotel = useCurrentWorkHotel();
	const { setCurrentWorkHotel } = useUserActions();
	const myHotels = useMyHotels();
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const afterHotelChangeCallback = useCallback(
		(newCurrentWorkHotel: RawHotel) => {
			if (!currentWorkHotel || currentWorkHotel.id !== newCurrentWorkHotel.id) {
				// user switch from currentWorkHotel to newCurrentWorkHotel
				// redirecting user to latest available date for this new hotel
				navigate(location.pathname.replace(/^(.*)\/\d{4}-\d{2}-\d{2}$/, '$1'));
			}
		},
		[currentWorkHotel, location, navigate],
	);
	const changeCurrentWorkHotel = useCallback(
		(hotel: RawHotel) => () => {
			setCurrentWorkHotel(hotel, afterHotelChangeCallback);
		},
		[setCurrentWorkHotel, afterHotelChangeCallback],
	);
	return (
		<NoContextPage>
			<Card>
				<CardContent>
					<Grid
						container
						direction="column"
						wrap="nowrap"
						sx={{ maxHeight: '90vh', overflow: 'scroll' }}
					>
						<Grid item>
							<Typography variant="h5">{t('hotel-chooser.title')}</Typography>
						</Grid>
						<Grid item>
							{myHotels.map((hotel) => (
								<HotelChooserHandle
									key={hotel.id}
									hotel={hotel}
									currentWorkHotel={currentWorkHotel}
									handleHotelSelection={changeCurrentWorkHotel}
								/>
							))}
						</Grid>
					</Grid>
				</CardContent>
			</Card>
		</NoContextPage>
	);
};
