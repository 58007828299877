export class UnauthorizedException extends Error {
	status: number;

	constructor() {
		super();
		this.name = 'UnauthorizedException';
		this.message = 'error.unauthorized';
		this.status = 401;
		this.stack = new Error().stack;
	}
}
