import { LinearLoaderContainer } from '../loader/LinearLoaderContainer';
import { Form as FormikForm, Formik, FormikValues } from 'formik';
import Debug from './Debug';
import { useFormManagement } from './useFormManagement';

type FormProps<T> = {
	initialValues: T;
	onSubmit: (values: T) => Promise<void>;
	children: ({ canSubmit }: { canSubmit: boolean }) => JSX.Element;
};

export const Form = <T extends FormikValues>({
	onSubmit,
	children,
	initialValues,
}: FormProps<T>) => {
	const { managedOnSubmit } = useFormManagement(onSubmit);
	return (
		<Formik initialValues={initialValues} onSubmit={managedOnSubmit}>
			{({ isSubmitting, errors }) => {
				return (
					<FormikForm>
						<LinearLoaderContainer isLoading={isSubmitting}>
							{children({ canSubmit: !isSubmitting && !Object.keys(errors).length })}
						</LinearLoaderContainer>
						<Debug />
					</FormikForm>
				);
			}}
		</Formik>
	);
};

export default Form;
