import { format as formatDate } from 'date-fns';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18n.use(HttpBackend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'fr',
		debug: process.env.NODE_ENV === 'development',
		backend: {
			loadPath: process.env.PUBLIC_URL + '/locales/{{lng}}/{{ns}}.json',
		},

		// have a common namespace used around the full app
		ns: ['translations'],
		defaultNS: 'translations',

		keySeparator: false, // we use content as keys

		interpolation: {
			escapeValue: false, // not needed for react!!
			formatSeparator: ',',
			format: function (value, format, lang) {
				if (format && value instanceof Date) {
					switch (format) {
						case 'verboseWithYear':
							return new Intl.DateTimeFormat(lang, {
								weekday: 'long',
								year: 'numeric',
								month: 'long',
								day: 'numeric',
							}).format(value);
						case 'verboseWithoutYear':
							return new Intl.DateTimeFormat(lang, {
								weekday: 'long',
								month: 'long',
								day: 'numeric',
							}).format(value);

						default:
							return formatDate(value, format);
					}
				}
				return value;
			},
		},
	});

type I18nextProviderProps = {
	children: React.ReactNode;
};

export const I18nextProvider: React.FC<I18nextProviderProps> = ({ children }) => {
	return <>{children}</>;
};
