export class TechnicalException extends Error {
	status: number;
	rejection: Error;

	constructor(rejection: Error) {
		super();
		this.name = 'TechnicalException';
		this.message = 'error.technical.fetch';
		this.status = -900;
		this.stack = new Error().stack;
		this.rejection = rejection;
	}
}
