export class FormatException extends Error {
	status: number;

	constructor() {
		super();
		this.name = 'FormatException';
		this.message = 'error.format';
		this.status = -901;
		this.stack = new Error().stack;
	}
}
