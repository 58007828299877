import HelpIcon from '@mui/icons-material/Help';
import BackIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Button } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormCard, TextField } from '../../../components/form';
import { NoContextPage } from '../../../components/NoContextPage/NoContextPage';
import { UnauthorizedException } from '../../../exceptions';
import { login, passwordResetInit } from '../../../services/auth';
import { useNotificationActions } from '../../../utils/notifications';
import { useUserActions } from '../../../utils/user';

type SigninFormProps = {
	handleSwitchForgotPassword: () => void;
};

const SigninForm: React.FC<SigninFormProps> = ({ handleSwitchForgotPassword }) => {
	const { notifyError } = useNotificationActions();
	const { initAuth } = useUserActions();
	const handleSignin = useCallback(
		({ username, password }: { username: string; password: string }) => {
			return login(username, password).then(
				(account) => {
					initAuth(account);
				},
				(err) => {
					if (err instanceof UnauthorizedException) {
						notifyError('signin.error.incorrect-credentials');
					} else {
						notifyError('error.technical');
					}
				},
			);
		},
		[notifyError, initAuth],
	);
	const { t } = useTranslation();
	return (
		<NoContextPage>
			<FormCard
				initialValues={{ username: '', password: '' }}
				onSubmit={handleSignin}
				content={() => (
					<>
						<TextField
							name="username"
							type="email"
							required
							labelKey="signin.form.username"
						/>
						<TextField
							name="password"
							type="password"
							required
							labelKey="signin.form.password"
						/>
					</>
				)}
				actions={({ canSubmit }) => (
					<>
						<Button onClick={handleSwitchForgotPassword} startIcon={<HelpIcon />}>
							{t('signin.form.forgot-password')}
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={!canSubmit}
						>
							{t('signin.form.submit')}
						</Button>
					</>
				)}
			/>
		</NoContextPage>
	);
};

type SignInForgotPasswordProps = {
	handleSwitchForgotPassword: () => void;
};

const SignInForgotPassword: React.FC<SignInForgotPasswordProps> = ({
	handleSwitchForgotPassword,
}) => {
	const { notifySuccess, notifyError } = useNotificationActions();
	const handleResetPassword = useCallback(
		({ username }: { username: string }) => {
			return passwordResetInit(username).then(
				() => {
					notifySuccess('signin-reset.success.receive-email');
				},
				() => {
					notifyError('signin-reset.error.generic');
				},
			);
		},
		[notifySuccess, notifyError],
	);
	const { t } = useTranslation();
	return (
		<NoContextPage>
			<FormCard
				initialValues={{ username: '' }}
				onSubmit={handleResetPassword}
				content={() => (
					<TextField
						name="username"
						type="email"
						required
						labelKey="signin.form.username"
					/>
				)}
				actions={({ canSubmit }) => (
					<>
						<Button onClick={handleSwitchForgotPassword} startIcon={<BackIcon />}>
							{t('signin-reset.form.back')}
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							disabled={!canSubmit}
						>
							{t('signin-reset.form.submit')}
						</Button>
					</>
				)}
			/>
		</NoContextPage>
	);
};

export const Signin = () => {
	const [isPasswordForgot, setIsPasswordForgot] = useState(false);
	const handleSwitchForgotPassword = useCallback(() => {
		setIsPasswordForgot(!isPasswordForgot);
	}, [setIsPasswordForgot, isPasswordForgot]);
	if (isPasswordForgot) {
		return <SignInForgotPassword handleSwitchForgotPassword={handleSwitchForgotPassword} />;
	}
	return <SigninForm handleSwitchForgotPassword={handleSwitchForgotPassword} />;
};
